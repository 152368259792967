// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
html {
    scroll-behavior: smooth;
  }
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root{
  display: flex;
   flex-direction: column;
   min-height: 100vh;
}

.page-content {
  height: calc(100vh - 38.5px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #CBEBDC;
}

.home-logo {
  max-height: 15%;
  max-width: 25%;
  border-radius: 20px;
}

.app-store-button {
  margin-top: 72px;
  margin-bottom: 16px;
  width: 150px;
   height: auto;
}


.footer-container{
  text-align: center;
   padding: 10px 0;
   margin-top: auto;
   text-align: center;
   border-top: 1px solid #ccc
}


.gabriela-regular {
  font-family: "Gabriela", serif;
  font-weight: 400;
  font-style: normal;
}

.bellefair-regular {
  font-family: "Bellefair", serif;
  font-weight: 400;
  font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;IACI,uBAAuB;EACzB;AACF;;;EAGE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,aAAa;GACZ,sBAAsB;GACtB,iBAAiB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;GACX,YAAY;AACf;;;AAGA;EACE,kBAAkB;GACjB,eAAe;GACf,gBAAgB;GAChB,kBAAkB;GAClB;AACH;;;AAGA;EACE,8BAA8B;EAC9B,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["\nhtml {\n    scroll-behavior: smooth;\n  }\n*,\n::after,\n::before {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n#root{\n  display: flex;\n   flex-direction: column;\n   min-height: 100vh;\n}\n\n.page-content {\n  height: calc(100vh - 38.5px);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  background-color: #CBEBDC;\n}\n\n.home-logo {\n  max-height: 15%;\n  max-width: 25%;\n  border-radius: 20px;\n}\n\n.app-store-button {\n  margin-top: 72px;\n  margin-bottom: 16px;\n  width: 150px;\n   height: auto;\n}\n\n\n.footer-container{\n  text-align: center;\n   padding: 10px 0;\n   margin-top: auto;\n   text-align: center;\n   border-top: 1px solid #ccc\n}\n\n\n.gabriela-regular {\n  font-family: \"Gabriela\", serif;\n  font-weight: 400;\n  font-style: normal;\n}\n\n.bellefair-regular {\n  font-family: \"Bellefair\", serif;\n  font-weight: 400;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
